import React from "react";

type Props = {
  children: React.ReactNode;
};

const FormWrapper = (props: Props) => {
  return (
    <div className="form-wrapper">
      <>{props.children}</>
    </div>
  );
};

export default FormWrapper;
